
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

	.product-border{
    display: grid;
    grid-template-columns: 100%;
		grid-template-rows: 100%;
		gap: 0 0;
		margin: 8px;
		align-items: stretch;
		flex-grow: 1;
		border: 1px solid $borderColor;
    position: relative;
    @include md {
      margin: 12px;
    }
    @include lg {
      margin: 16px;
    }

    &.hasImage {
      grid-template-columns: 33% 67%;
    }
		
		>div {
			align-self: center;
		}

    .product-details {
      padding: 8px 12px;
      .control {
        border: 1px solid $mainColor;
        color: $mainColor;
        width: 40px;
        height: 40px;
        align-self: end;
        user-select: none;

        display:flex;
        justify-content: center;
        align-items: center;

        &.active{
          border: none;
          color: $lightFont;
          background-color: $mainColor;

        }
      }

      .product-name {
        display: grid;
        grid-template-columns: 70% 30%;
        min-height: 40px;
        align-items: center;
        h3 {
          font-weight: bold;
          font-size: 18px;
        }
        .basket-controls {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .product-content {
        position: relative;
        .product-additive {
          position: absolute;
          width: 100%;
          background: $bgDark;
          color: $lightFont;
          border-radius: 10px;
          padding: 5px;
          margin: 5px 0;
          z-index: 1;
        }

        .fade-enter-active, .fade-leave-active {
          transition: all 0.5s;
        }
        .fade-enter-from, .fade-leave-to /* .list-leave-active below version 2.1.8 */ {
          opacity: 0;
          transform: translateY(calc(-100%));
        }
      }
    }

    .product-image{
			width: 100%;
      height: 100%;
      align-self: flex-start;
			
			img{
				width: 95%;
				height: 100%;
        vertical-align: middle;
        object-fit: cover;
			}
			margin-right: 8px;
		}

		.product-price{
      display: grid;
      grid-template-columns: 50% 50%;
			color: $mainColor;

      .plus-minus{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: auto;
        >div:last-child{
          margin-left: 4px;
        }
      }

      .basket-controls {
        position: absolute;
        bottom: 0;
        right: 0;
      }
		}
		
		.basket-controls{
			display: flex;
			flex-direction: row;
			align-self: stretch;
      justify-content: flex-end;
      //margin-top: auto;
      >div:last-child{
        margin-left: 4px;
      }
			@include md {
        min-width: 100px;
      }
		}
	}
