
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

	.spinner-border{
		margin-top: 10vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		min-height: 50vh;
	}
  
  .sticky_bottom{
    position: fixed;
    height:100px;
    top: 100vh;

  }
	.product-listing{
		position: relative;
		width: 100%;
		
		.nav{
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

      .clickable {
        display: flex;
        align-items: center;
      }
			
			.categories{
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				min-width: 90%;
        overflow: scroll;
        padding-bottom: 7px;
				.category-button{
					padding: 0 10px 0 10px;
          margin: 2px;
					border-radius: 20px;
          white-space: nowrap;
					&.selected{
						background-color: $mainColor;
					}
				}
			}
      .search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 20px;
        input {
          width: calc(90% - 20px);
          margin: 2px 0 6px 0;
          padding: 0 30px;
          background: transparent;
          border-bottom: 1px solid $shadowColor;
        }
        .searchCancel {
          margin: 0 0 4px -20px;
        }
      }
		}
		
		.listing{
			width: 100%;
			.category-heading{
				width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 8px 8px -2px rgba(0,0,0,0.1);
			}
		}
		
		.basket-container{
			position: sticky;
			bottom: 0px;
      padding: 24px 0;
      background: $bgColor;
			width: 100%;
			
			.basket-button{
				display: flex;
				flex-direction: row;
				margin: 0 auto;
				padding: 8px;
				border-radius: 25px;
				background-color: $mainColor;
				color: $lightFont;
				align-items: center;
				max-width: 700px;
				.icon{
					margin-left: 50px;
					margin-right: -50px;
				}
				.text{
					margin: 0 auto 0 auto;
				}
			}
		}
	}
