
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

	.basket{
		width: 100%;
		
		.basket-container{
      width: calc(100% - 20px);
      margin: 0 10px 0 10px;
      @include md {
        width: calc(100% - 40px);
        margin: 0 20px 0 20px;
      }
      @include xl {
        width: calc(100% - 60px);
        margin: 0 30px 0 30px;
      }
			
			.product-list{
				display: flex;
				flex-direction: column;
				width: 100%;
				
				.product{
          align-items: center;
          display: grid;
          grid-template-columns: 60% 5% 15% 20%;
          margin: 4px 0;
					width: 100%;
					
					div{
						input{
							width: 100%;
              text-align: center;
						}
						
						&:last-child{
							text-align: right;
						}
					}

          .control {
            text-align: center;
          }
				}
				
			}
		}
		
		.basket-summary{
			>div{
				display:flex;
				flex-direction: row;
				justify-content: space-between;
			}
		}

    .order-details {
      margin-top: 24px;
      .order-location {
        .location-wrapper {
          display: flex;
          align-items: center;
          .material-icons {
            margin-left: 8px;
          }
        }
      }
      .order-time{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        input {
          display: block;
        }
      }
      input[type='time'] {
        &:-webkit-datetime-edit-text {
          content: ':';
        }
        &::-webkit-datetime-edit-hour-field,
        &::-webkit-datetime-edit-minute-field {
          content: '--';
        }
      }

      label {
        display: block;
        margin-top: 16px;
      }

      .timeErrorMessage {
        border: solid red 1px;
        border-radius: 5px;
        background: rgba(255, 0, 0, 0.5);
        margin: 5px 0;
        padding: 5px;
      }

      .fade-enter-active, .fade-leave-active {
        transition: all 0.5s;
      }
      .fade-enter-from, .fade-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
      }

      .required {
        border: 1px solid red;
        margin: -1px;
      }
    }

    input,
    textarea {
      border-radius: 5px;
      padding: 4px 10px;
    }

    .continueShoppingButton {
      position: sticky;
      bottom: 0px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      background: $bgColor;
      z-index: 100;
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 700px;
        padding: 8px;
        color: $lightFont;
        .icon {
          margin-right: 30px;
        }
      }
    }
	}
