
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

  .burgerMenuWrapper {
    top: 12px;
    right: 12px;
    position: absolute;
    background: $bgColor;
    border-radius: 4px;
    padding: 8px;
    width: 42px;
    transition: all 0.15s;
    .toggleBurgerMenu {
      width: 28px;
      &:before,
      &:after,
      div {
        background: $bgDark;
        content: "";
        display: block;
        height: 4px;
        border-radius: 3px;
        margin: 4px 0;
        transition: all 0.5s;
      }
    }
    .menuContainer {
      display: none;
      padding: 12px;
      .menuList {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        a {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 8px 0;
        }
        .seperator {
          border: 1px solid $lightFont;
          margin: 20px;
        }
        button {
          text-align: center;
        }
      }
      .poweredByWrapper {
        position: absolute;
        bottom: 20px;
        text-align: center;
        div, a {
          font-size: 16px;
        }
        img {
          width: 30%;
        }
        a {
          cursor: pointer;
          text-decoration: underline;
          color: $lightFont;
        }
      }
    }
    &.openedMenu {
      position: fixed;
      top: 0;
      right: 0;
      border-radius: 0;
      width: 300px;
      height: 100%;
      background: $bgDark;
      color: $lightFont;
      z-index: 101;
      @include md {
        width: 450px;
      }
      .toggleBurgerMenu {
        position: absolute;
        top: 20px;
        right: 20px;
        &:before,
        &:after,
        div {
          background: $lightFont;
        }
        &:before {
          transform: translateY(8px) rotate(135deg);
        }
        &:after {
          transform: translateY(-8px) rotate(-135deg);
        }
        div {
          transform: scale(0);
        }
      }
      .menuContainer {
        display: block;
      }
    }
  }
