
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

.spinner-border{
		margin-top: 10vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		min-height: 50vh;
	}

  .welcomeWrapper {
    text-align: center;
    margin: 24px 12px 24px 12px;
    h2 {
      display: block;
      color: $mainColor;
      font-size: 36px;
      margin: 24px 0;
    }
  }
	
	.subsidiary-list{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		justify-content: space-evenly;
		
		>div{
			width: calc(100% - 10px);
      margin: 0 5px;
      @include md {
        width: calc(50% - 10px);
      }
      @include xl {
        width: calc(33% - 10px);
      }
		}
	}
