
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

	.subsidiary-card{
		background-color: $bgColor;
		width: 100%;
		display:flex;
		flex-direction: column;
		margin-bottom: 32px;
		cursor: pointer;
		
		.subsidiary-image-border{
			width: 100%;
      height: 175px;
      overflow: hidden;
			img{
				width: 100%;
        transform: scale(1);
        transition: all 0.3s;
        &:hover {
          transform: scale(1.1);
        }
			}
		}
		
		.subsidiary-image-fallback{
			display:flex;
			flex-direction: column;
			justify-content: center;
			background-color: $mainColor;
			height: 175px;
			text-align: center;
			
			h3{
				font-size: 48px;
			}
		}
		
		.subsidiary-adress-border{
			display:flex;
			flex-direction: column;
			text-align: center;
			justify-content: space-evenly;
			min-height: 100px;
      @include md {
        min-height: 200px;
      }
			box-shadow: 0px 6px 4px -6px $shadowColor;

      .bolded {
        font-size: 18px;
      }
		}
	}
