
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

	.headerBannerWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    .header-image{
      min-height: 200px;
      max-height: 400px;
      min-width: 100%;
      object-fit: cover;
    }
    .subsidiaryLocationWrapper {
      position: absolute;
      top: 0;
      left: 16px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      overflow: hidden;
      .logo {
        width: 25%;
        @include md {
          width: 15%;
        }
        @include xl {
          width: 10%;
        }
        max-height: 75%;
        z-index: 1;
        img {
          width: 100%;

        }
      }
      .city {
        display: flex;
        background: $bgColor;
        margin-left: -25px;
        padding: 5px 25px;
        border-radius: 0 10px 10px 0;
        z-index: 0;
      }

      .slide-in-enter-active, .fade-leave-active {
        transition: all 0.2s;
      }
      .slide-in-enter-from, .fade-leave-to {
        transform: translateX(calc(-100%));
      }
    }
  }
