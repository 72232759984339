
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

  .modalHeader {
    h3 {
      margin-top: 0;
      color: $mainColor;
    }
  }

  .modalBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;

    input {
      max-width: 20%;
    }
  }
