
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							
.Holma{
	@import "~@fontsource/nunito/index.css";

	$material-icons-font-path: '~material-icons/iconfont/';
	@import '~material-icons/iconfont/material-icons.css';

	@import 'global/_variables.scss';

	all: initial;
	* {
		all: unset;

		font-size: 16px;
		@include md {
			font-size: 20px;
		}
		@include xl {
			font-size: 24px;
		}
		font-family: "Nunito";
		box-sizing: border-box;
	}
	div{
		display: block;
	}

	hr {
		display: block;
		margin: 0.5em auto;
		border-style: inset;
		border-width: 1px;
		width: 100%;
	}

	input,
	textarea{
		background-color: rgba(0, 0, 0, 0.16);
		padding-bottom: 2px;
		
	}

	button{
		background-color: $mainColor;
		padding: 4px 16px 4px 16px;
		border-radius: 16px;
		cursor: pointer;
	}

	.content{
		background-color: #F7F7F7;
		width: 100%;
	}

	.material-icons {
		font-family: 'Material Icons';
		font-weight: normal;
		font-style: normal;
		font-size: 16px;
		@include md {
			font-size: 20px;
		}
		@include xl {
			font-size: 24px; /* Preferred icon size */
		}
		display: inline-block;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		font-feature-settings: 'liga';
	}

	.clickable{
		cursor: pointer;
	}

	.bolded {
		font-weight: bold;
		font-size: 24px;
		@include md {
			font-size: 28px;
		}
		@include xl {
			font-size: 32px;
		}
	}

}