
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

  .modalMask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
  }

  .modalWrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modalContainer {
    min-width: 300px;
    width: 50%;
    margin: 0 auto;
    padding: 20px 20px;
    background-color: $bgColor;
    border-radius: 10px;
    box-shadow: 0 2px 8px $shadowColor;
    position: relative;
    text-align: center;
  }

  .modalClose {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity 0.5s ease;
  }

  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
  }
