
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

	.back-button{
		width: 100%;
		background-color: $bgDark;
		color: $lightFont;
		margin: 4px 0;
    padding-left: 15px;
		span{
			padding: 4px 0 4px 0;
      vertical-align: middle;
		}
	}
