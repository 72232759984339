
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

  .errorMessageWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    h2 {
      font-size: 40px;
      margin: 16px 0;
    }
    > div {
      margin: 8px 0;
    }
  }
