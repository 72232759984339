
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

.modalHeader {
  h3 {
    margin-top: 0;
    color: $mainColor;
  }
}

.modalBody {
  margin: 20px 0;
  div {
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
