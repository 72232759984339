
								@import "/home/mkoegler/node/holma/src/scss/global/_variables.scss";
							

  .modalHeader {
    h3 {
      margin-top: 0;
      color: $mainColor;
    }
  }

  .modalBody {
    margin: 20px 0;
    .notificationWrapper {
      margin: 5px 0;
      padding: 5px;
      border: 1px solid red;
      border-radius: 5px;
      background: rgba(255,0,0,0.3);
    }
    .dateSelectionWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 95%;
      button {
        margin: 10px;
      }
      span {
        margin: 10px 0;
        padding: 4px;
      }
    }
    .datePickerWrapper {
      display: grid;
      grid-template-columns: 85% 15%;
      @include md {
        max-width: 400px;
        margin: 0 auto;
      }
      input {
        width: 100%;
        margin: 10px 0 10px 0;
        padding: 3px 10px;
        border-radius: 5px 0 0 5px;
        background: $mainColor;
        text-align: center;
        &[type="date"] {
          &:not(.has-value) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &::-webkit-datetime-edit {
              display: none;
            }
            &::-webkit-calendar-picker-indicator {

            }
            &:before {
              color: $lightFont;
              content: attr(placeholder);
              width: 100%;
            }
          }
        }
      }
      input[type="date"]:not(.has-value):before{

      }
      button {
        margin: 10px 0 10px 0;
        border-radius: 0 5px 5px 0;
        border: solid $mainColor 1px;
        background: transparent;
        padding: 3px 10px;
      }
    }

    .fade-enter-active, .fade-leave-active {
      transition: all 0.5s;
    }
    .fade-enter-from, .fade-leave-to /* .list-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }
